import { put, spawn, takeLatest } from 'redux-saga/effects';
import { apiSuccess, get, post } from 'utils/api';
import * as APP from 'commons/reducer';
import { base64ToPdf } from 'utils/functions';
import { Public } from '@material-ui/icons';
import { INSURANCE } from '../reducer';

function* fetchBike() {
  yield takeLatest(INSURANCE.FETCH_BIKE, function* (action) {
    const response = yield get(`api/v1/insurance/bike/${action.payload}`);
    console.log(response);
    if (!response.error) {
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
      yield put(apiSuccess(INSURANCE.FETCH_BIKE_SUCCESS));
    } else {
      yield put(apiSuccess(INSURANCE.FETCH_BIKE_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: `Bike error - ${response.errorMsg}` }));
    }
  });
}

function* registerInsuranceQuotes() {
  yield takeLatest(INSURANCE.REGISTER_INSURANCE_QUOTES, function* (action) {
    let response;
    if (!action.payload.data.public) {
      response = yield post(
        `api/v1/insurance/quotes/${action.payload.transferId}`,
        action.payload.data,
      );
    } else {
      response = yield post(
        `api/v1/p/insurance/quotes/${action.payload.transferId}`,
        action.payload.data,
      );
    }

    if (!response.error) {
      yield put(apiSuccess(INSURANCE.REGISTER_INSURANCE_QUOTES_SUCCESS));
      yield put(apiSuccess(INSURANCE.SET_QUOTES, response.quotes));
    } else {
      yield put(apiSuccess(INSURANCE.REGISTER_INSURANCE_QUOTES_ERROR));
      yield put(apiSuccess(INSURANCE.CHANGE_STEP, '1'));
      yield put(
        apiSuccess(APP.SET_ERROR, {
          errorMsg:
            response?.status === 503
              ? 'Error al obtener la cotización de seguro, intente nuevamente.'
              : `Registrar seguro error - ${response.errorMsg}`,
        }),
      );
    }
  });
}

function* generatePdfInsuranceBasicQuote() {
  yield takeLatest(INSURANCE.GENERATE_PDF_INSURANCE_BASIC_QUOTE, function* (action) {
    let response;
    if (action.payload.public) {
      response = yield get(`api/v1/insurance/quotes/pdf/${action.payload.quote}`);
    } else {
      response = yield get(`api/v1/p/insurance/quotes/pdf/${action.payload.quote}`);
    }

    if (response.error) {
      yield put(apiSuccess(INSURANCE.GENERATE_PDF_INSURANCE_QUOTE_ERROR));
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: `Generar pdf seguro error - ${response.errorMsg}` }),
      );
    } else {
      base64ToPdf(response.pdfObject);
      yield put(apiSuccess(INSURANCE.GENERATE_PDF_INSURANCE_QUOTE_SUCCESS));
    }
  });
}
function* generatePdfInsuranceFullQuote() {
  yield takeLatest(INSURANCE.GENERATE_PDF_INSURANCE_FULL_QUOTE, function* (action) {
    let response;
    if (action.payload.public) {
      response = yield get(`api/v1/insurance/quotes/pdf/${action.payload.quote}`);
    } else {
      response = yield get(`api/v1/p/insurance/quotes/pdf/${action.payload.quote}`);
    }

    if (response.error) {
      yield put(apiSuccess(INSURANCE.GENERATE_PDF_INSURANCE_QUOTE_ERROR));
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: `generar pdf seguro error - ${response.errorMsg}` }),
      );
    } else {
      base64ToPdf(response.pdfObject);
      yield put(apiSuccess(INSURANCE.GENERATE_PDF_INSURANCE_QUOTE_SUCCESS));
    }
  });
}

function* acceptFullInsuranceQuote() {
  yield takeLatest(INSURANCE.ACCEPT_BASIC_INSURANCE_QUOTE, function* (action) {
    const response = yield post(
      `api/v1/insurance/quotes/${action.payload.transferId}/accept/${action.payload.bciQuoteId}/1`,
      { userContact: action.payload.user, fees: action.payload.fees },
    );
    if (response.error) {
      yield put(apiSuccess(INSURANCE.ACCEPT_INSURANCE_QUOTE_ERROR));
      yield put(apiSuccess(INSURANCE.CHANGE_STEP, '1'));
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: `Aceptar seguro error - ${response.errorMsg}` }),
      );
    } else if (response.notResponse) {
      yield put(apiSuccess(INSURANCE.ACCEPT_INSURANCE_QUOTE_NOT_PROCESSED));
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: 'Hubo un error, vuelve a presionar el botón' }),
      );
    } else {
      yield put(apiSuccess(INSURANCE.ACCEPT_INSURANCE_QUOTE_SUCCESS, response));
    }
  });
}
function* acceptBasicInsuranceQuote() {
  yield takeLatest(INSURANCE.ACCEPT_FULL_INSURANCE_QUOTE, function* (action) {
    const response = yield post(
      `api/v1/insurance/quotes/${action.payload.transferId}/accept/${action.payload.bciQuoteId}/2`,
      { userContact: action.payload.user, fees: action.payload.fees },
    );
    if (response.error) {
      yield put(apiSuccess(INSURANCE.ACCEPT_INSURANCE_QUOTE_ERROR));
      yield put(apiSuccess(INSURANCE.CHANGE_STEP, '1'));
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: `Aceptar seguro error - ${response.errorMsg}` }),
      );
    } else if (response.notResponse) {
      yield put(apiSuccess(INSURANCE.ACCEPT_INSURANCE_QUOTE_NOT_PROCESSED));
      yield put(
        apiSuccess(APP.SET_ERROR, { errorMsg: 'Hubo un error, vuelve a presionar el botón' }),
      );
    } else {
      yield put(apiSuccess(INSURANCE.ACCEPT_INSURANCE_QUOTE_SUCCESS, response));
    }
  });
}
function* fetchStatusInsuranceQuote() {
  yield takeLatest(INSURANCE.STATUS_INSURANCE_QUOTE, function* (action) {
    const response = yield post(
      `api/v1/insurance/quotes/${action.payload.transferId}/status/${action.payload.bciQuoteId}`,
    );
    if (!response.error) {
      yield put(apiSuccess(INSURANCE.STATUS_INSURANCE_QUOTE_SUCCESS));
    } else {
      yield put(apiSuccess(INSURANCE.STATUS_INSURANCE_QUOTE_ERROR));
      yield put(apiSuccess(APP.SET_ERROR, { errorMsg: `Pago error - ${response.errorMsg}` }));
    }
  });
}

export default function* saga() {
  yield spawn(fetchBike);
  yield spawn(registerInsuranceQuotes);
  yield spawn(generatePdfInsuranceBasicQuote);
  yield spawn(generatePdfInsuranceFullQuote);
  yield spawn(acceptBasicInsuranceQuote);
  yield spawn(acceptFullInsuranceQuote);
  yield spawn(fetchStatusInsuranceQuote);
}
