import React from 'react';
import { Box, Typography } from '@material-ui/core';

import CustomInputNumber from 'commons/components/CustomInputNumber';
import colors from '../../../utils/colors';

const { primary } = colors;

const SalePrice = ({
  disabled = false,
  handleBlur = null,
  handleChange,
  isEditMode,
  priceToShow,
  value,
  percentageDiscount,
}) => {
  const price = parseInt(priceToShow.split('$')[1].replace(/\./g, ''), 10); // Parse numeric value from string
  const discountedPrice =
    percentageDiscount && price ? Math.round(price * (1 - percentageDiscount / 100)) : null;

  return isEditMode ? (
    <Box>
      <CustomInputNumber
        disabled={disabled}
        label="Precio de venta (CLP)"
        name="price"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        error={false}
        helperText=""
      />
    </Box>
  ) : (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      {percentageDiscount ? (
        <>
          {/* Discount Percentage Badge */}
          <Box
            display="inline-block"
            bgcolor="secondary.main"
            px={1}
            py={0.5}
            borderRadius={1}
            mb={1}>
            <Typography
              variant="caption"
              style={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '0.75rem', // Matches the earlier font size
              }}>
              {`${Math.floor(percentageDiscount)}% OFF`}
            </Typography>
          </Box>

          {/* Prices */}
          <Box display="flex" alignItems="baseline">
            {/* Original Price */}
            <Typography
              variant="body2"
              style={{
                textDecoration: 'line-through',
                color: '#9e9e9e',
                marginRight: '8px',
              }}>
              <span itemProp="priceCurrency" content="CLP">
                $
              </span>
              <span itemProp="price">{price.toLocaleString('es-CL')}</span>
            </Typography>

            {/* Discounted Price */}
            <Typography
              variant="body1"
              color="primary"
              style={{
                fontWeight: '700',
                fontSize: '2.125rem',
                fontFamily: 'Mark Pro',
              }}>
              <span itemProp="priceCurrency" content="CLP">
                $
              </span>
              <span itemProp="price">{discountedPrice.toLocaleString('es-CL')}</span>
            </Typography>
          </Box>
        </>
      ) : (
        <Typography
          variant="body1"
          color="primary"
          style={{
            fontWeight: '700',
            fontSize: '2.125rem',
            fontFamily: 'Mark Pro',
          }}>
          <span itemProp="priceCurrency" content="CLP">
            $
          </span>
          <span itemProp="price">{price.toLocaleString('es-CL')}</span>
        </Typography>
      )}
    </Box>
  );
};

export default SalePrice;
