import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Button,
  Card,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
  Box,
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CustomCheckBox from 'commons/components/CustomCheckBox';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import CustomInputFile from 'commons/components/CustomInputFile';
import CustomTitle from 'commons/components/CustomTitle';
import CustomInputNumber from 'commons/components/CustomInputNumber';
import CustomInputAdornment from 'commons/components/CustomInputAdornment';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import HelpGoodPhotos from 'commons/components/HelpGoodPhotos';
import ImagesSection from 'commons/components/ImagesSection';
import { PATHS } from 'utils/paths';
import CustomMultiSelectComplete from 'commons/components/CustomMultiSelectComplete';

const PilotBikeOfferForm = ({
  onSubmit,
  inputs = [],
  controls,
  actions,
  onControlChange,
  errors,
  loading,
  openAlert,
  clearAlert = null,
  profileComplete,
  className,
  isMobileScreen,
  history,
  user,
  isBicycleSelected,
  setUnlogged,
  unlogged,
}) => (
  <Card>
    <CardContent>
      <Grid container justifyContent="center">
        <Grid container xs={12} sm={12} justifyContent="center">
          <Typography className={className?.primaryTitle} align="center" component="h1">
            ¿Buscas una bicicleta certificada?
          </Typography>
        </Grid>
        {/* <Grid container xs={12} sm={12} justifyContent="center">
          <Typography color="primary" className={className?.secondaryTitle} align="center">
            Te ayudamos a encontrarla
          </Typography>
        </Grid> */}
        <Grid container xs={12} sm={12} justifyContent="center">
          <Typography className={className?.informativeText} align="center">
            Déjanos tu solicitud y nosotros te encontraremos la mejor opción para ti.
          </Typography>
        </Grid>
        {!profileComplete && !unlogged && (
          <Grid container spacing={2} justify="center">
            {/* First Button */}
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  padding: '12px',
                  borderRadius: '12px',
                  minWidth: '300px', // Ensures a good width
                  maxWidth: '400px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => history.push(`/login?href=${PATHS.OFFER_FOR_BIKE}`)}>
                Realizar solicitud como <br /> usuario BIKFY
              </Button>
            </Grid>

            {/* Second Button */}
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{
                  textTransform: 'none',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  padding: '12px',
                  borderRadius: '12px',
                  minWidth: '300px',
                  maxWidth: '400px',
                  display: 'flex',
                  justifyContent: 'center',
                }}
                onClick={() => setUnlogged(true)}>
                Realizar solicitud como <br /> invitado
              </Button>
            </Grid>
          </Grid>
        )}

        {(profileComplete || unlogged) && (
          <Grid item xs={10} sm={10}>
            <form onSubmit={onSubmit} autoComplete="off">
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
                className={className?.marginTopBottom}>
                {inputs.map((i) =>
                  i.type === 'multicomplete' && i.visible ? (
                    <Grid key={i.name} item xs={12} sm={6} md={i.md ? i.md : 6} align="center">
                      <CustomMultiSelectComplete
                        name={i.name}
                        label={i.label}
                        value={controls[i.name]}
                        options={i.options}
                        onChange={onControlChange}
                        error={!!errors[i.name]}
                        errors={errors[i.name]}
                      />
                    </Grid>
                  ) : i.type === 'text' && i.visible ? (
                    <Grid
                      key={i.name}
                      item
                      xs={12}
                      sm={i.last ? 10 : 6}
                      md={i.md ? i.md : 6}
                      align="center">
                      <TextField
                        name={i.name}
                        label={i.label}
                        variant="outlined"
                        fullWidth
                        value={
                          i.getValue && !controls.foreignCheckbox
                            ? i.getValue(controls[i.name])
                            : controls[i.name]
                        }
                        onChange={(e) => onControlChange(e, i.getValue, !controls.foreignCheckbox)}
                        error={!!errors[i.name]}
                        helperText={errors[i.name] && errors[i.name][0]}
                        InputProps={
                          i.inputProp && {
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }
                        }
                      />
                    </Grid>
                  ) : i.type === 'number' && i.visible ? (
                    <Grid key={i.name} item xs={12} sm={6} md={i.md ? i.md : 6} align="center">
                      <CustomInputNumber
                        label={i.label}
                        name={i.name}
                        onChange={onControlChange}
                        value={controls[i.name]}
                        error={!!errors[i.name]}
                        helperText={errors[i.name]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  ) : i.type === 'select' && i.visible ? (
                    <Grid key={i.name} item xs={12} sm={6} md={i.md ? i.md : 6} align="center">
                      <CustomAutoComplete
                        info={i.info}
                        name={i.name}
                        label={i.label}
                        value={controls[i.name]}
                        onChange={onControlChange}
                        options={i.options}
                        error={!!errors[i.name]}
                        errors={errors[i.name]}
                      />
                    </Grid>
                  ) : null,
                )}
                {!isMobileScreen && (
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    justifyContent="center"
                    className={className?.marginTop}>
                    <Grid container xs={12} sm={6} md={6} justifyContent="center">
                      <Button
                        onClick={() => history.push('/')}
                        variant="contained"
                        color="secondary"
                        disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Volver'}
                      </Button>
                    </Grid>
                    <Grid container xs={12} sm={6} md={6} justifyContent="center">
                      <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? <CircularProgress size={24} /> : 'Enviar Oferta'}
                      </Button>
                    </Grid>
                  </Grid>
                )}
                {isMobileScreen && (
                  <Grid container xs={12} sm={12} md={12} justifyContent="center">
                    <Grid
                      container
                      xs={12}
                      sm={6}
                      md={6}
                      justifyContent="center"
                      className={className?.buttonStyle}>
                      <Button type="submit" variant="contained" color="primary" disabled={loading}>
                        {loading ? (
                          <CircularProgress size={24} style={{ color: 'white' }} />
                        ) : (
                          'Enviar Oferta'
                        )}
                      </Button>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      sm={6}
                      md={6}
                      justifyContent="center"
                      className={className?.buttonStyle}>
                      <Button
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        onClick={() => history.push('/')}>
                        {loading ? (
                          <CircularProgress
                            size={24}
                            className={className?.whiteCircularProgress}
                          />
                        ) : (
                          'Volver'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </form>
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
);

export default PilotBikeOfferForm;
