import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { PATHS } from 'utils/paths';
import CustomAutoComplete from 'commons/components/CustomAutoComplete';
import CustomInputNumber from 'commons/components/CustomInputNumber';

const BuyYourBikeForm = ({
  profileComplete,
  className,
  onSubmit,
  inputs = [],
  controls,
  actions,
  onControlChange,
  offerType,
  offerLabel,
  errors,
  validateFields,
  history,
}) => {
  // State to handle modals and form visibility
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [openFormModal, setOpenFormModal] = useState(false);

  const handleOpenPolicyModal = () => {
    setOpenPolicyModal(true);
  };

  const handleClosePolicyModal = () => {
    setOpenPolicyModal(false);
  };

  const handleOpenFormModal = () => {
    setOpenFormModal(true);
  };

  const handleCloseFormModal = () => {
    setOpenFormModal(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateFields(inputs.map((input) => input.name));
    if (isValid) {
      onSubmit({
        bikeData: { ...controls, offerType },
        history,
      });
      handleCloseFormModal();
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={12} container justifyContent="center">
        <Typography className={className?.primaryTitle} align="center" component="h1">
          ¡Gracias por tu interés en nuestro servicio de
          <Typography
            color="primary"
            variant="span"
            className={className?.secondaryTitle}
            align="center">
            {` ${offerLabel.toLowerCase()}!`}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} container justifyContent="center">
        <Typography className={className?.informativeText} align="center">
          Para continuar, realiza una solicitud completando la siguiente información.
        </Typography>
      </Grid>

      {!profileComplete && (
        <Grid container spacing={2} justify="center">
          {/* First Button */}
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              style={{
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '16px',
                padding: '12px',
                borderRadius: '12px',
                minWidth: '300px', // Set a fixed width
                maxWidth: '400px', // Prevent it from going too wide
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => history.push('/login?href=registerBike')}>
              Realizar solicitud con <br /> Bicicleta registrada
            </Button>
          </Grid>

          {/* Second Button */}
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              style={{
                textTransform: 'none',
                fontWeight: 'bold',
                fontSize: '16px',
                padding: '12px',
                borderRadius: '12px',
                minWidth: '300px',
                maxWidth: '400px',
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={handleOpenFormModal}>
              Realizar solicitud como <br /> invitado
            </Button>
          </Grid>
        </Grid>
      )}

      {/* Button to open policy modal */}
      <Grid item xs={12} sm={12} container justifyContent="center" style={{ marginBottom: '10px' }}>
        <Box mt={3}>
          <Button variant="outlined" color="primary" onClick={handleOpenPolicyModal}>
            Ver Política de Retoma de Bicicletas Usadas
          </Button>
        </Box>
      </Grid>

      {/* Modal for displaying the policy */}
      <Dialog open={openPolicyModal} onClose={handleClosePolicyModal} maxWidth="md" fullWidth>
        <DialogTitle>Política de Retoma de Bicicletas Usadas</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1" paragraph>
            Con el objetivo de que nuestras bicicletas usadas sean las más convenientes del mercado,
            hemos establecido las siguientes condiciones para la prestación del servicio:
          </Typography>
          <Typography variant="body2" component="div">
            <ul>
              <li>
                La bicicleta debe estar registrada correctamente con su boleta o factura de compra.
              </li>
              <li>
                Valor de la bicicleta: El precio de venta público debe ser de al menos $1.500.000.
              </li>
            </ul>
          </Typography>
          <Typography variant="body1" paragraph>
            Paso a paso para solicitar el servicio de retoma:
          </Typography>
          <Typography variant="body2" component="div">
            <ol>
              <li>
                Incorpora al menos 3 fotos recientes de tu bicicleta (lavada) en el registro de tu
                bicicleta aquí{' '}
                <Link to="/home" target="_blank" rel="noopener noreferrer">
                  aquí
                </Link>
                .
              </li>
              <li>
                Envía una solicitud de retoma{' '}
                <Link to="/buy-your-bike" target="_blank" rel="noopener noreferrer">
                  aquí
                </Link>
                , incluyendo el valor que deseas recibir por la bicicleta y el estado de la misma.
              </li>
              <li>
                Si las condiciones son adecuadas, nos pondremos en contacto para ofrecer un rango de
                precios.
              </li>
              <li>Si aceptas el rango de precios, debes traer la bicicleta a nuestro showroom.</li>
              <li>Revisaremos la bicicleta y ofreceremos un valor final.</li>
              <li>El cupón puede usarse como método de pago en nuestras tiendas asociadas.</li>
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePolicyModal} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal for form */}
      <Dialog open={openFormModal} onClose={handleCloseFormModal} maxWidth="sm" fullWidth>
        <DialogTitle>Completa tu información</DialogTitle>
        <DialogContent dividers>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              {inputs.map((input) => (
                <Grid
                  item
                  xs={12}
                  sm={input.sm ? input.sm : 6}
                  md={input.md ? input.md : 6}
                  key={input.name}
                  align="center">
                  {input.type === 'select' && input.visible ? (
                    <CustomAutoComplete
                      info={input.info}
                      name={input.name}
                      label={input.label}
                      value={controls[input.name]}
                      onChange={onControlChange}
                      options={input.options}
                      error={!!errors[input.name]}
                      errors={errors[input.name]}
                    />
                  ) : input.type === 'text' && input.visible ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={input.label}
                      name={input.name}
                      value={controls[input.name] || ''}
                      onChange={onControlChange}
                      error={!!errors[input.name]}
                      helperText={errors[input.name] || ''}
                      type="text"
                      disabled={input.disabled}
                    />
                  ) : input.type === 'number' && input.visible ? (
                    <CustomInputNumber
                      label={input.label}
                      name={input.name}
                      onChange={onControlChange}
                      value={controls[input.name]}
                      error={!!errors[input.name]}
                      helperText={errors[input.name]}
                    />
                  ) : null}
                </Grid>
              ))}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFormModal} color="default">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default BuyYourBikeForm;
