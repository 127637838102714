const styles = (theme) => ({
  primaryTitle: {
    fontWeight: '700',
    fontSize: '2.0rem',
    marginTop: '20px',
  },
  whiteCircularProgress: {
    color: 'white',
  },
  secondaryTitle: {
    fontWeight: '700',
    fontSize: '2.0rem',
  },
  informativeText: {
    fontWeight: '500',
    fontSize: '1.7rem',
    marginTop: '15px',
    marginBottom: '15px',
  },
  optionsLog: {
    fontWeight: '500',
    fontSize: '1.4rem',
    marginTop: '15px',
    marginBottom: '15px',
  },
  marginTopBottom: {
    marginTop: '15px',
    marginBottom: '15px',
  },
  marginTop: {
    marginTop: '15px',
  },
  [theme.breakpoints.down('md')]: {
    primaryTitle: {
      fontSize: '2rem',
    },
    informativeText: {
      fontWeight: '500',
      fontSize: '0.9rem',
    },
    secondaryTitle: {
      fontSize: '1.7rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    buttonStyle: {
      marginTop: '10px',
    },
  },
});

export default styles;
