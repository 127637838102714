import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import { apiSuccess, get, post, postForm } from 'utils/api';
import { BUY_YOUR_BIKE } from '../reducer';

function* fetchBrands() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_BRANDS));
    const response = yield get('api/v1/brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_BRANDS_ERROR, { errorMsg: 'Error al cargar las marcas' }),
      );
    }
  });
}

function* fetchVehicleTypes() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES));
    const response = yield get('api/v1/vehicle-types');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES_ERROR, {
          errorMsg: 'Error al cargar los tipos de vehículos',
        }),
      );
    }
  });
}

function* fetchModels() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_MODELS));
    const response = yield get('api/v1/models');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_MODELS));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_MODELS_ERROR, {
          errorMsg: 'Error al cargar los modelos',
        }),
      );
    }
  });
}

function* fetchMotorbikeModels() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS));
    const response = yield get('api/v1/motorbike-models');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS_ERROR, {
          errorMsg: 'Error al cargar los modelos',
        }),
      );
    }
  });
}

function* fetchCurrencies() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_CURRENCIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_CURRENCIES));
    const response = yield get('api/v1/currencies');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_CURRENCIES));
    if (!response.error) {
      const clpCurrency = Object.values(response.objects.currencies)
        .filter((currency) => currency.name === 'CLP')
        .pop();
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_CURRENCIES_SUCCESS, clpCurrency));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_CURRENCIES_ERROR, {
          errorMsg: 'Error al cargar las monedas',
        }),
      );
    }
  });
}

function* fetchSizes() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_SIZES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_SIZES));
    const response = yield get('api/v1/sizes');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_SIZES));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_SIZES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_SIZES_ERROR, { errorMsg: 'Error al cargar las tallas' }),
      );
    }
  });
}
function* fetchCategories() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_CATEGORIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_CATEGORIES));
    const response = yield get('api/v1/categories');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_CATEGORIES));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_CATEGORIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_CATEGORIES_ERROR, {
          errorMsg: 'Error al cargar las categorias',
        }),
      );
    }
  });
}

function* fetchRims() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_RIMS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_RIMS));
    const response = yield get('api/v1/rims');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_RIMS));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_RIMS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_RIMS_ERROR, { errorMsg: 'Error al cargar los aros' }),
      );
    }
  });
}

function* sendSimulation() {
  yield takeLatest(BUY_YOUR_BIKE.SEND_SIMULATION, function* (action) {
    const { user, bikeData, sellMode, history } = action.payload;
    const jsonBody = {
      user: user?.user,
      bikeData,
    };

    const response = yield post('api/v1/p/simulation', jsonBody);
    if (!response.error) {
      yield put(
        apiSuccess(APP.SET_SUCCESS, 'La solicitud de venta de tu bike fue enviada al equipo Bikfy'),
      );
      history.push('/');
      return;
    }
    yield put(apiSuccess(BUY_YOUR_BIKE.SEND_SIMULATION_ERROR));
    yield put(
      apiSuccess(APP.SET_ERROR, {
        errorMsg: 'Ha ocurrido un error al enviar los datos al equipo Bikfy',
      }),
    );
  });
}

function* fetchMotorbikeBrands() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS));
    const response = yield get('api/v1/motorbike-brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS_ERROR, {
          errorMsg: 'Error al cargar las marcas',
        }),
      );
    }
  });
}

function* fetchPurchasableBikes() {
  yield takeLatest(BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES));
    const response = yield get('api/v1/bikes/purchasable');
    yield put(apiSuccess(APP.REMOVE_LOADING, BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES));
    if (!response.error) {
      // yield put(apiSuccess(BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES_SUCCESS, response));
      yield put({ type: BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES_SUCCESS, payload: response });
    } else {
      yield put(
        apiSuccess(BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES_ERROR, {
          errorMsg: 'Error al cargar las bicicletas comprables',
        }),
      );
    }
  });
}

export default function* root() {
  yield spawn(fetchBrands);
  yield spawn(fetchModels);
  yield spawn(fetchSizes);
  yield spawn(fetchCurrencies);
  yield spawn(sendSimulation);
  yield spawn(fetchVehicleTypes);
  yield spawn(fetchMotorbikeBrands);
  yield spawn(fetchMotorbikeModels);
  yield spawn(fetchPurchasableBikes);
  yield spawn(fetchRims);
  yield spawn(fetchCategories);
}
