import React, { useEffect, useState } from 'react';
import { composedComponent } from 'utils/functions';
import { Box, Typography, useMediaQuery, withStyles, useTheme } from '@material-ui/core';
import { appActions } from 'commons/reducer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { PATHS } from 'utils/paths';
import { insuranceActions } from './reducer';
import saga from './saga';
import BeneficiaryForm from './components/BeneficiaryForm/BeneficiaryForm';
import BannerCard from './components/BannerCard/BannerCard';
import BikeForm from './components/BikeForm/BikeForm';
import styles from './styles';
import PlanSelectorCard from './components/PlanSelectorCard/PlanSelectorCard';
import { AcceptConditions } from './components/AcceptConditions/AcceptConditions';
import { PaymentWindow } from './components/PaymentWindow/PaymentWindow';
import { InsuranceProcesing } from './components/InsuranceProcesing/InsuranceProcesing';
import { SafariUserAgentModal } from './components/SafariUserAgentModal/SafariUserAgentModal';

const Insurance = (props) => {
  const { actions, classes, user, objects, insurance } = props;
  const { bike, brands, models, useTypes, transfers, comunes } = objects;
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const bikeSelected = Object.values(bike)[0] ?? null;
  const { transferId } = params;
  const { step, formControls, quotes } = insurance;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isWindowOpen, setIsWindowOpen] = useState(null);

  const [isSafariUserModalOpen, setIsSafariUserModalOpen] = useState(false);

  const handleProfile = () => {
    history.push(PATHS.PROFILE, { prevPath: location.pathname });
  };

  // Checking if user is using Safari
  useEffect(() => {
    const { userAgent } = navigator;

    // Detecting if userAgent is Chrome (Because also has 'Safari' in the userAgent)
    const chromeAgent = userAgent.indexOf('Chrome') > -1;

    // Detecting if userAgent is Safari
    let safariAgent = userAgent.indexOf('Safari') > -1;

    // If both validations are true, it means the user is from Chrome
    // If only Safari agent is true, it means the user is from Safari
    if (chromeAgent && safariAgent) safariAgent = false;
    if (safariAgent) {
      setIsSafariUserModalOpen(true);
    }
  }, []);

  // Verifica si ya se tiene registrada una cotización para la bicicleta seleccionada
  useEffect(() => {
    if (!transferId) {
      const beneficiaryMockData = {
        name: 'John',
        firstLastName: 'Doe',
        secondLastName: 'Smith',
        email: 'johndoe@example.com',
        phone: '+56912345678',
        birthdate: '1990-01-01',
        addressStreet: 'Main Street',
        addressNumber: '1234',
        comune: 'Las Condes',
        comuneId: 105,
        rut: '49.645.289',
        dv: '5',
      };
      const bikeMockData = {
        useTypeId: '1',
        transferId: '4682',
        brandId: 99,
        brand: 'Estándar',
        modelId: 9999,
        model: 'X',
        year: 2022,
        version: 'V1',
        amountInsurable: '2500000',
      };
      actions.changeBikeControls(bikeMockData);
      actions.changeBeneficiaryControls(beneficiaryMockData);
    } else if (formControls.bike.transferId !== transferId || !bikeSelected) {
      actions.changeStep('1');
      actions.fetchBike(transferId);
    }
  }, [transferId]);
  // Ingresa los datos necesarios en el formulario de la bicicleta
  useEffect(() => {
    if (bikeSelected) {
      let bikeInitialState = {};
      const rut_dv = user?.user.taxNumber.split('-');
      const beneficiaryInitialState = {
        name: user?.user.name,
        firstLastName: user?.user.firstLastName,
        secondLastName: user?.user.secondLastName,
        email: user?.user.email,
        phone: user?.user.phone,
        birthdate: user?.user.birthday.split('T')[0],
        addressStreet: user?.user.addressStreet,
        addressNumber: user?.user.addressNumber,
        comune: comunes[user?.user?.comuneId]?.name,
        comuneId: user?.user.comuneId,
        rut: rut_dv[0],
        dv: rut_dv[1],
      };
      // if this is true, it means that the state of the insurance page already has information of another bike, so we
      // update the state with the information of the actual Bike that the user wants to insure
      if (formControls.bike.transferId !== transferId) {
        bikeInitialState = {
          useTypeId: '',
          transferId,
          brandId: bikeSelected?.brandId,
          brand: brands[bikeSelected?.brandId]?.name,
          modelId: bikeSelected?.modelId,
          model: models[bikeSelected?.modelId]?.name,
          year: bikeSelected?.year,
          version: bikeSelected?.version,
          amountInsurable: transfers[transferId]?.amount ?? 0,
        };
      } else {
        bikeInitialState = {
          transferId,
          brandId:
            formControls.bike.brandId !== bikeSelected?.brandId
              ? bikeSelected?.brandId
              : formControls.bike.brandId,
          brand:
            formControls.bike.brand !== brands[bikeSelected?.brandId]?.name
              ? brands[bikeSelected?.brandId]?.name
              : formControls.bike.brand,
          modelId:
            formControls.bike.modelId !== bikeSelected?.modelId
              ? bikeSelected?.modelId
              : formControls.bike.modelId,
          model:
            formControls.bike.model !== models[bikeSelected?.modelId]?.name
              ? models[bikeSelected?.modelId]?.name
              : formControls.bike.model,
          year:
            formControls.bike.year !== bikeSelected?.year
              ? bikeSelected?.year
              : formControls.bike.year,
          version:
            formControls.bike.version !== bikeSelected?.version
              ? bikeSelected?.version
              : formControls.bike.version,
          amountInsurable:
            formControls.bike.amountInsurable !== transfers[transferId]?.amount
              ? transfers[transferId]?.amount
              : formControls.bike.amountInsurable,
        };
      }
      actions.changeBikeControls(bikeInitialState);
      actions.changeBeneficiaryControls(beneficiaryInitialState);
    }
  }, [bikeSelected]);

  useEffect(() => {
    if (quotes.selected && quotes.selected.bciQuoteId && quotes.selected.bciQuoteId.length > 0) {
      console.log('selected');
    }
  }, [quotes.selected]);

  useEffect(() => {
    if (isWindowOpen === false) {
      console.log('IS CLOSED');
      actions.fetchStatusInsuranceQuote({
        bciQuoteId: quotes.selected.bciQuoteId,
        transferId: formControls.bike.transferId,
      });
    }
  }, [isWindowOpen]);

  const useTypesOptions = Object.values(useTypes).map((s) => ({
    value: s?.id,
    label: s?.name,
  }));
  const bikeFormValues = [
    {
      label: 'Marca',
      name: 'brand',
      type: 'text',
      disabled: true,
      visible: true,
    },
    {
      label: 'Modelo',
      name: 'model',
      type: 'text',
      disabled: true,
      visible: true,
    },
    {
      label: 'Año',
      name: 'year',
      type: 'text',
      disabled: true,
      visible: true,
    },
    {
      label: 'Version',
      name: 'version',
      type: 'text',
      disabled: true,
      visible: true,
    },
    {
      label: 'Uso específico',
      name: 'useTypeId',
      type: 'select',
      options: useTypesOptions,
      visible: !!transferId,
    },
    {
      label: 'Monto a asegurar',
      name: 'amountInsurable',
      type: 'number',
      disabled: false,
      visible: true,
    },
  ];

  let content;
  if (isWindowOpen || isWindowOpen === null) {
    content = (
      <>
        <Box>
          <Typography
            variant="h4"
            component="h1"
            align="center"
            style={{ textTransform: 'uppercase' }}>
            Simula tu <a className={`${classes.bikfyColor}`}>seguro </a>
          </Typography>
        </Box>
        <br />
        {step === '1' && !!transferId && <BeneficiaryForm handleProfile={handleProfile} />}
        <br />
        <BikeForm bikeFormValues={bikeFormValues} transferId={transferId} />
        {/* {(step === '1') && (
          <>
            <br />
            <AcceptConditions />
          </>
        )} */}
        {step === '1' && !isMobileScreen && (
          <>
            <br />
            <BannerCard />
          </>
        )}
        <br />
        {step === '2' && <PlanSelectorCard transferId={transferId} />}
        {step === '3' && (
          <PaymentWindow url={quotes.selected.paymentUrl} setIsWindowOpen={setIsWindowOpen} />
        )}
      </>
    );
  } else {
    content = <InsuranceProcesing />;
  }

  return (
    <>
      {content}
      <SafariUserAgentModal
        isSafariUserModalOpen={isSafariUserModalOpen}
        setIsSafariUserModalOpen={setIsSafariUserModalOpen}
        classes={classes}
      />
    </>
  );
};
export default composedComponent(Insurance, saga, {
  saga: 'sagaInsurance',
  middlewares: [withStyles(styles)],
  states: ['app.user', 'app.objects', 'insurance'],
  actions: [insuranceActions, appActions],
});
