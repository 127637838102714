/* eslint-disable import/order */
// @ts-nocheck
/* eslint-disable import/no-unresolved */
import { createStore, compose, combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createContext } from 'react';
import localForage from 'localforage';
// project
import { clone } from 'utils/functions';
import enhancers, { sagaMiddleware } from './middleware';
// reducers

import app from 'commons/reducer';
import login from 'screens/Login/reducer';
import home from 'screens/Home/reducer';
import recoveryPassword from 'screens/RecoveryPassword/reducer';
import register from 'screens/Register/reducer';
import profile from 'screens/Profile/reducer';
import pilotOfferBike from 'screens/PilotOfferBike/reducer';
import storeReducer from 'screens/Store/reducer';
import bike from 'screens/Bike/reducer';
import transfer from 'screens/Transfer/reducer';
import transferPreview from 'screens/TransferPreview/reducer';
import report from 'screens/Report/reducer';
import search from 'screens/Search/reducer';
import payment from 'screens/Payment/reducer';
import activate from 'screens/Activate/reducer';
import maintenance from 'screens/Maintenance/reducer';
import ambassador from 'screens/Ambassador/reducer';
import benefit from 'screens/Benefit/reducer';
import admin from 'screens/Admin/reducer';
import enablePayment from 'screens/EnablePayment/enablePaymentScreen.reducer';
import salesMethod from 'screens/TransferSalesMethod/reducer';
import bankData from 'screens/BankData/bankDataScreen.reducer';
import buyerPayMethod from 'screens/BuyerPayMethod/buyerPayMethodScreen.reducer';
import userInsurance from 'screens/UserInsurance/userInsurance.reducer';
import selectedInsurance from 'screens/SelectedInsurance/SelectedInsurance.reducer';
import bikeInsurance from 'screens/BikeInsurance/bikeInsurance.reducer';
import adminPay from 'screens/AdminPay/adminPay.reducer';
import bikeDetail from '../screens/BikeDetail/reducer';
import adminSale from 'screens/AdminSale/reducer';
import cancelBikeRegistration from 'screens/CancelBikeRegistration/reducer';
import insurance from 'screens/Insurance/reducer';
import downloadBikeDataStore from 'screens/StoreDownloadBikeData/reducer';
import subscription from 'screens/Subscription/reducer';
import buyYourBike from 'screens/BuyYourBike/reducer';
import purchasePreview from 'screens/PurchasePreview/reducer';
import redeemCoupon from 'screens/RedeemCoupon/reducer';

// MARKETPLACE

//-----------------------------------------------------------
export const ReactReduxContext = createContext({});

export default function configureStore(initialState = {}) {
  const persistConfig = {
    key: 'root',
    storage: localForage,
    whitelist: [],
  };

  // create reducer
  const reducers = combineReducers(
    clone(clone.OBJECT, {
      home,
      login,
      app,
      recoveryPassword,
      register,
      profile,
      pilotOfferBike,
      store: storeReducer,
      bike,
      transfer,
      transferPreview,
      report,
      search,
      payment,
      activate,
      maintenance,
      ambassador,
      benefit,
      admin,
      enablePayment,
      salesMethod,
      bankData,
      buyerPayMethod,
      userInsurance,
      selectedInsurance,
      bikeInsurance,
      adminPay,
      bikeDetail,
      adminSale,
      cancelBikeRegistration,
      insurance,
      downloadBikeDataStore,
      subscription,
      buyYourBike,
      purchasePreview,
      redeemCoupon,
    }),
  );

  const pReducer = persistReducer(persistConfig, reducers);
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  const store = createStore(pReducer, initialState, composeEnhancers(enhancers));

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedSagas = {}; // Saga registry

  return store;
}
