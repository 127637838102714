import { createAction, createReducer } from 'utils/reducer';

// Local constants
const PATH = 'buyYourBike/';

const CHANGE_CONTROLS = `${PATH}CHANGE_CONTROLS`;

// Global constants for saga
export const BUY_YOUR_BIKE = {
  VEHICLE_TYPE_CHANGE_RESET_CONTROLS: `${PATH}VEHICLE_TYPE_CHANGE_RESET_CONTROLS`,
  FETCH_BRANDS: `${PATH}FETCH_BRANDS`,
  FETCH_BRANDS_SUCCESS: `${PATH}FETCH_BRANDS_SUCCESS`,
  FETCH_BRANDS_ERROR: `${PATH}FETCH_BRANDS_ERROR`,
  FETCH_MODELS: `${PATH}FETCH_MODELS`,
  FETCH_MODELS_SUCCESS: `${PATH}FETCH_MODELS_SUCCESS`,
  FETCH_MODELS_ERROR: `${PATH}FETCH_MODELS_ERROR`,
  FETCH_SIZES: `${PATH}FETCH_SIZES`,
  FETCH_SIZES_SUCCESS: `${PATH}FETCH_SIZES_SUCCESS`,
  FETCH_SIZES_ERROR: `${PATH}FETCH_SIZES_ERROR`,
  FETCH_CURRENCIES: `${PATH}FETCH_CURRENCIES`,
  FETCH_CURRENCIES_SUCCESS: `${PATH}FETCH_CURRENCIES_SUCCESS`,
  FETCH_CURRENCIES_ERROR: `${PATH}FETCH_CURRENCIES_ERROR`,
  FETCH_CATEGORIES: `${PATH}FETCH_CATEGORIES`,
  FETCH_CATEGORIES_SUCCESS: `${PATH}FETCH_CATEGORIES_SUCCESS`,
  FETCH_CATEGORIES_ERROR: `${PATH}FETCH_CATEGORIES_ERROR`,
  FETCH_RIMS: `${PATH}FETCH_RIMS`,
  FETCH_RIMS_SUCCESS: `${PATH}FETCH_RIMS_SUCCESS`,
  FETCH_RIMS_ERROR: `${PATH}FETCH_RIMS_ERROR`,

  FETCH_VEHICLE_TYPES: `${PATH}FETCH_VEHICLE_TYPES`,
  FETCH_VEHICLE_TYPES_ERROR: `${PATH}FETCH_VEHICLE_TYPES_ERROR`,
  FETCH_VEHICLE_TYPES_SUCCESS: `${PATH}FETCH_VEHICLE_TYPES_SUCCESS`,

  FETCH_MOTORBIKE_BRANDS: `${PATH}FETCH_MOTORBIKE_BRANDS`,
  FETCH_MOTORBIKE_BRANDS_SUCCESS: `${PATH}FETCH_MOTORBIKE_BRANDS_SUCCESS`,
  FETCH_MOTORBIKE_BRANDS_ERROR: `${PATH}FETCH_MOTORBIKE_BRANDS_ERROR`,

  SEND_BIKE_OFFER: `${PATH}SEND_BIKE_OFFER`,
  SEND_BIKE_OFFER_SUCCESS: `${PATH}SEND_BIKE_OFFER_SUCCESS`,
  SEND_BIKE_OFFER_ERROR: `${PATH}SEND_BIKE_OFFER_ERROR`,

  FETCH_MOTORBIKE_MODELS: `${PATH}FETCH_MOTORBIKE_MODELS`,
  FETCH_MOTORBIKE_MODELS_SUCCESS: `${PATH}FETCH_MOTORBIKE_MODELS_SUCCESS`,
  FETCH_MOTORBIKE_MODELS_ERROR: `${PATH}FETCH_MOTORBIKE_MODELS_ERROR`,

  FETCH_PURCHASABLE_BIKES: `${PATH}FETCH_PURCHASABLE_BIKES`,
  FETCH_PURCHASABLE_BIKES_SUCCESS: `${PATH}FETCH_PURCHASABLE_BIKES_SUCCESS`,
  FETCH_PURCHASABLE_BIKES_ERROR: `${PATH}FETCH_PURCHASABLE_BIKES_ERROR`,

  SEND_SIMULATION: `${PATH}SEND_SIMULATION`,
  SEND_SIMULATION_SUCCESS: `${PATH}SEND_SIMULATION_SUCCESS`,
  SEND_SIMULATION_ERROR: `${PATH}SEND_SIMULATION_ERROR`,
};

// actions
export const buyYourBikeActions = {
  changeControls: createAction(CHANGE_CONTROLS, 'payload'),
  sendBikeOffer: createAction(BUY_YOUR_BIKE.SEND_BIKE_OFFER, 'payload'),
  fetchBrands: createAction(BUY_YOUR_BIKE.FETCH_BRANDS),
  fetchModels: createAction(BUY_YOUR_BIKE.FETCH_MODELS),
  fetchSizes: createAction(BUY_YOUR_BIKE.FETCH_SIZES),
  fetchCurrencies: createAction(BUY_YOUR_BIKE.FETCH_CURRENCIES),
  fetchCategories: createAction(BUY_YOUR_BIKE.FETCH_CATEGORIES),
  fetchRims: createAction(BUY_YOUR_BIKE.FETCH_RIMS),
  fetchVehicleTypes: createAction(BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES),
  fetchMotorbikeBrands: createAction(BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS),
  fetchMotorbikeModels: createAction(BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS),
  changeVehicleTypeResetControls: createAction(BUY_YOUR_BIKE.VEHICLE_TYPE_CHANGE_RESET_CONTROLS),
  fetchPurchasableBikes: createAction(BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES),
  sendSimulation: createAction(BUY_YOUR_BIKE.SEND_SIMULATION, 'payload'),
};

const initialState = {
  formControls: {
    modelId: null,
    version: '',
    sizeId: null,
    rimId: null,
    currencyId: null,
    amount: 0,
    brandId: null,
    vehicleTypeId: 1,
    year: null,
    condition: null,
    phone: '+56',
  },
  loading: false,
  error: false,
  purchasableBikes: [],
};

const buyYourBike = createReducer(initialState, {
  [CHANGE_CONTROLS](state, action) {
    state.formControls = {
      ...state.formControls,
      ...action.payload,
    };
  },
  [BUY_YOUR_BIKE.VEHICLE_TYPE_CHANGE_RESET_CONTROLS](state) {
    state.formControls = {
      ...initialState.formControls,
      vehicleTypeId: state.formControls.vehicleTypeId,
      amount: state.formControls.amount,
      currencyId: state.formControls.currencyId,
      maxYear: state.formControls.maxYear,
      minYear: state.formControls.minYear,
    };
  },
  [BUY_YOUR_BIKE.FETCH_CURRENCIES](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_CURRENCIES_SUCCESS](state, action) {
    state.formControls = {
      ...state.formControls,
      currencyId: action.payload.id,
    };
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_CURRENCIES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES_SUCCESS](state, action) {
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_VEHICLE_TYPES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.FETCH_SIZES](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_SIZES_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_SIZES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.FETCH_CATEGORIES](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_CATEGORIES_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_CATEGORIES_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BUY_YOUR_BIKE.FETCH_RIMS](state) {
    state.bikeLoading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_RIMS_SUCCESS](state) {
    state.bikeLoading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_RIMS_ERROR](state, action) {
    state.bikeLoading = false;
    state.error = true;
    state.message = action.payload.errorMsg;
  },
  [BUY_YOUR_BIKE.FETCH_MODELS](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_MODELS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_MODELS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_MOTORBIKE_MODELS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.SEND_BIKE_OFFER](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.SEND_BIKE_OFFER_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.SEND_BIKE_OFFER_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_MOTORBIKE_BRANDS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.FETCH_BRANDS](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_BRANDS_SUCCESS](state) {
    state.loading = false;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_BRANDS_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
  [BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES](state) {
    state.loading = true;
    state.error = false;
  },
  [BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES_SUCCESS](state, action) {
    return {
      ...state,
      purchasableBikes: action.payload.purchasableBikes,
      loading: false,
      error: false,
    };
  },
  [BUY_YOUR_BIKE.FETCH_PURCHASABLE_BIKES_ERROR](state) {
    state.loading = false;
    state.error = true;
  },
});

export default buyYourBike;
