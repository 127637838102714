import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { composedComponent } from 'utils/functions';
import { Typography, withStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { appActions } from 'commons/reducer';
import { pilotOfferBikeActions } from 'screens/PilotOfferBike/reducer';
import { useHistory, useLocation } from 'react-router-dom';
import years from 'utils/years';
import { useForm } from 'utils/hooks/useForm';
import styles from './styles';
import saga from './saga';
import PilotBikeOfferForm from './components/pilotBikeOfferForm';

const PilotOfferBike = (props) => {
  const { classes, user } = props;
  const [isBicycleSelected, setIsBicycleSelected] = useState(true);
  const [unlogged, setUnlogged] = useState(false);

  const isUserDataCompleted = props?.user?.user?.dataComplete === true;
  const date = new Date();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    props.actions.fetchBrands();
    props.actions.fetchModels();
    props.actions.fetchCategories();

    // props.actions.fetchCurrencies();
    props.actions.fetchSizes();
    // props.actions.fetchVehicleTypes();
    // props.actions.fetchMotorbikeBrands();
    // props.actions.fetchMotorbikeModels();
  }, [
    props.actions.fetchBrands,
    props.actions.fetchModels,
    props.actions.fetchCategories,
    // props.actions.fetchCurrencies,
    props.actions.fetchSizes,
    // props.actions.fetchVehicleTypes,
    // props.actions.fetchMotorbikeBrands,
    // props.actions.fetchMotorbikeModels,
  ]);

  useEffect(() => {
    const vehicleTypeSelected = Object.values(props?.vehicleTypes)
      .filter((vehicleType) => vehicleType.id === props?.formControls?.vehicleTypeId)
      .pop();
    if (vehicleTypeSelected?.name && vehicleTypeSelected?.name !== 'Bicicleta') {
      setIsBicycleSelected(false);
    } else {
      setIsBicycleSelected(true);
    }
  }, [props.formControls.vehicleTypeId, props?.vehicleTypes]);

  const sizes = Object.values(props.sizes).map((s) => ({
    value: s?.id,
    label: s?.name,
  }));

  const currencies = props.order?.currencies
    ?.map((b) => props.currencies[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const categories = props.order?.categories
    ?.map((c) => props.categories[c])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const vehicleTypes = props.order?.vehicleTypes
    ?.map((vt) => props.vehicleTypes[vt])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const motorbikeBrands = props.order?.motorbikeBrands
    ?.map((mb) => props.motorbikeBrands[mb])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const brands = props.order?.brands
    ?.map((b) => props.brands[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const models = props.order?.models
    ?.map((m) => props.models[m])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
      brandId: a?.brandId,
    }));

  const motorbikeModels = props.order?.motorbikeModels
    ?.map((mm) => props.motorbikeModels[mm])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
      brandId: a?.brandId,
    }));

  const filteredModels = isBicycleSelected
    ? models?.filter((m) => props?.formControls?.brandId?.includes(m.brandId))
    : motorbikeModels?.filter((m) => props?.formControls?.brandId?.includes(m.brandId));

  const inputs = [
    // {
    //   name: 'vehicleTypeId',
    //   label: 'Tipo de vehículo',
    //   type: 'select',
    //   visible: true,
    //   md: 12,
    //   options: vehicleTypes,
    // },
    {
      name: 'name',
      label: 'Nombre',
      type: 'text',
      visible: Boolean(unlogged),
    },
    {
      name: 'lastname',
      label: 'Apellido',
      type: 'text',
      visible: Boolean(unlogged),
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      visible: Boolean(unlogged),
    },
    {
      name: 'phone',
      label: 'Telefono',
      type: 'text',
      visible: Boolean(unlogged),
    },
    {
      name: 'categoryId',
      label: 'Categoría',
      type: 'select',
      options: categories,
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'brandId',
      label: 'Marca',
      type: 'multicomplete',
      options: isBicycleSelected ? brands : motorbikeBrands,
      info: true,
      visible: true,
    },
    {
      name: 'minYear',
      label: 'Año mínimo',
      type: 'select',
      options: years(),
      md: 3,
      visible: true,
    },
    {
      name: 'maxYear',
      label: 'Año máximo',
      type: 'select',
      options: years(),
      md: 3,
      visible: true,
    },
    // {
    //   name: 'year',
    //   label: 'Año',
    //   type: 'select',
    //   options: years(),
    //   // md: 3,
    //   visible: true,
    // },

    {
      name: 'modelId',
      label: 'Modelo',
      type: 'multicomplete',
      options: filteredModels,
      info: true,
      visible: true,
    },
    {
      name: 'sizeId',
      label: 'Talla',
      type: 'select',
      options: sizes,
      visible: isBicycleSelected,
    },
    // {
    //   name: 'version',
    //   label: 'Versión',
    //   type: 'text',
    //   visible: true,
    // },
    // {
    //   name: 'currencyId',
    //   label: 'Moneda',
    //   type: 'select',
    //   options: currencies,
    //   md: isBicycleSelected ? 2 : 6,
    //   visible: true,
    // },
    {
      name: 'expectedPrice',
      label: 'Ingresa el precio que buscas',
      type: 'number',
      inputProp: true,
      md: isBicycleSelected ? 3 : 6,
      visible: true,
    },
    // {
    //   name: 'maxPrice',
    //   label: 'Ingresa el precio máximo que deseas pagar',
    //   type: 'number',
    //   inputProp: true,
    //   md: isBicycleSelected ? 3 : 6,
    //   visible: true,
    // },
  ];

  const validateYears = (year, minMax) => {
    let isValid = true;
    if (minMax === 'min' && props.formControls.maxYear && props.formControls.minYear && year) {
      isValid = Number(year) < Number(props.formControls.maxYear);
      return isValid;
    }
    if (minMax === 'max' && props.formControls.minYear && props.formControls.maxYear && year) {
      isValid = Number(year) > Number(props.formControls.minYear);
      return isValid;
    }
    return isValid;
  };

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({
      ...(name === 'brandId' ? { modelId: '' } : {}),
      [name]: value,
    });
    if (name === 'vehicleTypeId') {
      props.actions.changeVehicleTypeResetControls();
    }
  }, []);

  const { onChange, onSubmit, errors } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      vehicleTypeId: [
        {
          check: (value) => !!value,
          message: 'El tipo de vehículo es requerido',
        },
      ],
      brandId: [
        {
          check: (value) => !!value.length,
          message: 'La Marca es requerida',
        },
      ],
      modelId: [
        {
          check: (value) => !!value.length,
          message: 'El Modelo es requerido',
        },
      ],
      sizeId: [
        {
          check: (value) => !!value || !isBicycleSelected,
          message: 'La Talla es requerida',
        },
      ],
      minYear: [
        {
          check: (value) => !!value,
          message: 'El Año es requerido',
        },
        {
          check: (value) => validateYears(value, 'min'),
          message: 'Debe ser menor al año máx',
        },
      ],
      maxYear: [
        {
          check: (value) => !!value,
          message: 'El Año es requerido',
        },
        {
          check: (value) => validateYears(value, 'max'),
          message: 'Debe ser mayor al año min',
        },
      ],
      categoryId: [
        {
          check: (value) => !!value,
          message: 'Requerida',
        },
      ],
      expectedPrice: [
        {
          check: (value) => !!value,
          message: 'El Valor es requerido',
        },
      ],
      // maxPrice: [
      //   {
      //     check: (value) => !!value,
      //     message: 'El Valor es requerido',
      //   },
      // ],
      // year: [
      //   {
      //     check: (value) => !!value,
      //     message: 'El Valor es requerido',
      //   },
      // ],
      email: [
        {
          check: (value) => !!value || !unlogged,
          message: 'El Valor es requerido',
        },
      ],
      name: [
        {
          check: (value) => !!value || !unlogged,
          message: 'El Valor es requerido',
        },
      ],
      lastname: [
        {
          check: (value) => !!value || !unlogged,
          message: 'El Valor es requerido',
        },
      ],
      phone: [
        {
          check: (value) => (!!value && value.trim().length > 0) || !unlogged,
          message: 'El Teléfono celular es requerido',
        },
        {
          check: (value) =>
            (value.substr(0, 3) === '+56' ? value.trim().length >= 12 : value.trim().length >= 8) ||
            !unlogged,
          message: 'El formato es incorrecto',
        },
      ],
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    inputs.forEach((input) => {
      if (input.status === true) {
        changeControls(input.name, '');
      }
    });
    const {
      brandId,
      amount,
      currencyId,
      categoryId,
      maxYear,
      minYear,
      modelId,
      sizeId,
      version,
      vehicleTypeId,
      name,
      email,
      phone,
      lastname,
      expectedPrice,
    } = props.formControls;
    onSubmit(() =>
      props.actions.sendBikeOffer({
        user: props?.user?.user,
        bikeRequest: {
          brands: brandId,
          amount,
          currencyId,
          maxYear,
          minYear,
          models: modelId,
          sizeId,
          version,
          vehicleTypeId,
          name,
          email,
          phone,
          lastname,
          expectedPrice,
          categoryId,
        },
        history,
      }),
    );
  };

  return (
    <>
      <PilotBikeOfferForm
        className={classes}
        controls={props.formControls}
        inputs={inputs}
        onControlChange={onChange}
        errors={errors}
        profileComplete={user?.user?.dataComplete}
        isMobileScreen={isMobileScreen}
        onSubmit={handleSubmit}
        loading={props.loading}
        history={history}
        user={user}
        isBicycleSelected={isBicycleSelected}
        setUnlogged={setUnlogged}
        unlogged={unlogged}
      />
    </>
  );
};

export default composedComponent(PilotOfferBike, saga, {
  saga: 'sagaPilotOfferBike',
  middlewares: [withStyles(styles)],
  states: [
    'pilotOfferBike.formControls',
    'pilotOfferBike.loading',
    'pilotOfferBike.error',
    'app.objects.vehicleTypes',
    'app.objects.brands',
    'app.objects.models',
    'app.objects.motorbikeBrands',
    'app.objects.sizes',
    'app.objects.currencies',
    'app.objects.motorbikeModels',
    'app.objects.categories',
    'app.objects',
    'app.order',
    'app.user',
  ],
  actions: [pilotOfferBikeActions, appActions],
});
