import { put, spawn, takeLatest } from 'redux-saga/effects';
import * as APP from 'commons/reducer';
import apiRequest, { apiSuccess, get, post, postForm } from 'utils/api';
import { PILOT_OFFER_BIKE } from '../reducer';

function* fetchBrands() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_BRANDS));
    const response = yield get('api/v1/brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_BRANDS_ERROR, { errorMsg: 'Error al cargar las marcas' }),
      );
    }
  });
}

function* fetchMotorbikeBrands() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_MOTORBIKE_BRANDS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_MOTORBIKE_BRANDS));
    const response = yield get('api/v1/motorbike-brands');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_MOTORBIKE_BRANDS));
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_MOTORBIKE_BRANDS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_MOTORBIKE_BRANDS_ERROR, {
          errorMsg: 'Error al cargar las marcas',
        }),
      );
    }
  });
}
function* fetchCategories() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_CATEGORIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_CATEGORIES));
    const response = yield get('api/v1/categories');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_CATEGORIES));
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_CATEGORIES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_CATEGORIES_ERROR, {
          errorMsg: 'Error al cargar las categorias',
        }),
      );
    }
  });
}

function* fetchVehicleTypes() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_VEHICLE_TYPES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_VEHICLE_TYPES));
    const response = yield get('api/v1/vehicle-types');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_VEHICLE_TYPES));
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_VEHICLE_TYPES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_VEHICLE_TYPES_ERROR, {
          errorMsg: 'Error al cargar los tipos de vehículos',
        }),
      );
    }
  });
}

function* fetchModels() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_MODELS));
    const response = yield get('api/v1/models');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_MODELS));
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_MODELS_ERROR, {
          errorMsg: 'Error al cargar los modelos',
        }),
      );
    }
  });
}

function* fetchMotorbikeModels() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_MOTORBIKE_MODELS, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_MOTORBIKE_MODELS));
    const response = yield get('api/v1/motorbike-models');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_MOTORBIKE_MODELS));
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_MOTORBIKE_MODELS_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_MOTORBIKE_MODELS_ERROR, {
          errorMsg: 'Error al cargar los modelos',
        }),
      );
    }
  });
}

function* fetchCurrencies() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_CURRENCIES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_CURRENCIES));
    const response = yield get('api/v1/currencies');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_CURRENCIES));
    if (!response.error) {
      const clpCurrency = Object.values(response.objects.currencies)
        .filter((currency) => currency.name === 'CLP')
        .pop();
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_CURRENCIES_SUCCESS, clpCurrency));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_CURRENCIES_ERROR, {
          errorMsg: 'Error al cargar las monedas',
        }),
      );
    }
  });
}

function* fetchSizes() {
  yield takeLatest(PILOT_OFFER_BIKE.FETCH_SIZES, function* (action) {
    yield put(apiSuccess(APP.ADD_LOADING, PILOT_OFFER_BIKE.FETCH_SIZES));
    const response = yield get('api/v1/sizes');
    yield put(apiSuccess(APP.REMOVE_LOADING, PILOT_OFFER_BIKE.FETCH_SIZES));
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.FETCH_SIZES_SUCCESS));
      yield put(apiSuccess(APP.GET_STATE_FROM_API_SUCCESS, response));
    } else {
      yield put(
        apiSuccess(PILOT_OFFER_BIKE.FETCH_SIZES_ERROR, { errorMsg: 'Error al cargar las tallas' }),
      );
    }
  });
}

function* sendPilotOffer() {
  yield takeLatest(PILOT_OFFER_BIKE.SEND_BIKE_OFFER, function* (action) {
    // const { user, bikeRequest, history } = action.payload;
    // console.log(action.payload, 'asdasd)');
    // const body = new FormData();
    // const jsonBody = {
    //   user,
    //   bikeRequest,
    // };
    // console.log(jsonBody);

    // body.append('jsonBody', JSON.stringify(jsonBody));
    // console.log(body);

    // const response = yield postForm('api/v1/p/send-bike-offer-email', body);
    const { user, bikeRequest, sellMode, history } = action.payload;
    const jsonBody = {
      user,
      bikeRequest,
    };

    const response = yield post('api/v1/p/send-bike-offer-email', jsonBody);
    if (!response.error) {
      yield put(apiSuccess(PILOT_OFFER_BIKE.SEND_BIKE_OFFER_SUCCESS));
      yield put(
        apiSuccess(
          APP.SET_SUCCESS,
          'La información de la bike que buscas fue enviada al equipo Bikfy',
        ),
      );
      history.push('/');
      return;
    }
    yield put(apiSuccess(PILOT_OFFER_BIKE.SEND_BIKE_OFFER_ERROR));
    yield put(
      apiSuccess(APP.SET_ERROR, {
        errorMsg: 'Ha ocurrido un error al enviar los datos al equipo Bikfy',
      }),
    );
  });
}

export default function* root() {
  yield spawn(fetchBrands);
  yield spawn(fetchModels);
  yield spawn(fetchSizes);
  yield spawn(fetchCurrencies);
  yield spawn(sendPilotOffer);
  yield spawn(fetchVehicleTypes);
  yield spawn(fetchMotorbikeBrands);
  yield spawn(fetchMotorbikeModels);
  yield spawn(fetchCategories);
}
