import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
  makeStyles,
  Tooltip,
  DialogTitle,
  DialogContent as MuiDialogContent,
  ListItemText,
  List,
  ListItem,
  Link,
  DialogActions as MuiDialogActions,
  ListItemIcon,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { useHistory } from 'react-router-dom';
import logoBikfy from 'commons/assets/bikfylab_logo.png';
import styles from './styles';

const useStyles = makeStyles(styles);

// Store data
const associatedStores = [
  { name: 'BIKFYLAB', url: '/publish-bike' },
  { name: 'Specialized', url: 'https://www.specialized.com/cl/es' },
  { name: 'Giant', url: 'https://giant-bicycles.cl/' },
  { name: 'Colnago', url: 'https://www.dolomitas.cl/' },
  { name: 'Wilier', url: 'https://all4bikers.cl/' },
  { name: 'BMC', url: 'https://www.365imports.cl/' },
  { name: 'Aurum', url: 'https://www.365imports.cl/' },
  { name: 'De Rosa', url: 'https://www.365imports.cl/' },
  { name: 'BH', url: 'https://www.epicbike.cl/' },
  { name: 'Mondraker', url: 'https://extremezone.cl/' },
];

// Store modal component
const StoreModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>Tiendas Asociadas</DialogTitle>
    <MuiDialogContent dividers>
      <List>
        {associatedStores.map((store, index) => (
          <ListItem key={index} divider>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Link href={store.url} target="_blank" rel="noopener noreferrer" underline="hover">
                  {store.name}
                </Link>
              )}
            />
          </ListItem>
        ))}
      </List>
    </MuiDialogContent>
    <MuiDialogActions>
      <Button onClick={onClose} color="primary">
        Cerrar
      </Button>
    </MuiDialogActions>
  </Dialog>
);

export default function InformativeModal(props) {
  const history = useHistory();
  const classes = useStyles();
  const { open, closeInformativeModal } = props;

  const [storeModalOpen, setStoreModalOpen] = useState(false);

  const handleStoreModalOpen = () => setStoreModalOpen(true);
  const handleStoreModalClose = () => setStoreModalOpen(false);

  return (
    <>
      <Dialog open={!open} onClose={closeInformativeModal} className={classes.dialog} maxWidth="sm" fullWidth>
        <DialogContent
          style={{
            textAlign: 'center',
            backgroundColor: '#3BA3B2',
            color: 'white',
          }}
        >
          <img src={logoBikfy} alt="Logo Bikfy" style={{ width: '50%', marginBottom: '16px' }} />

          <DialogContentText>
            <Typography style={{ color: '#323E48', fontWeight: 'bold' }}>
              ¡RENUEVA TU BICI CON BIKFYLAB!
            </Typography>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'white',
              }}
            >
              <Typography style={{ marginTop: '8px' }}>
                Déjanos tu bici actual y tú solo preocúpate de elegir a tu nueva compañera 🚴‍♀️✨
              </Typography>
              <Typography style={{ marginTop: '8px' }}>
                Recibe un cupón digital al instante para usarlo en
                {' '}
                <Tooltip title="Ver tiendas asociadas" placement="top">
                  <span
                    onClick={handleStoreModalOpen}
                    style={{
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                  >
                    nuestras tiendas asociadas
                  </span>
                </Tooltip>
                {' '}
                o en una semi-nueva nuestra.
              </Typography>
              <Typography style={{ marginTop: '8px' }}>
                ¡Fácil, rápido y sin complicaciones!
              </Typography>
            </div>

            <Typography style={{ color: 'white', marginTop: '16px', fontStyle: 'italic' }}>
              Tu próxima bici te está esperando 🧡
            </Typography>
          </DialogContentText>
        </DialogContent>

        <DialogActions style={{ textAlign: 'center', backgroundColor: '#3BA3B2', color: 'white' }}>
          <Grid container justifyContent="center">
            <Button
              style={{ backgroundColor: '#323E48' }}
              onClick={() => {
                closeInformativeModal();
                history.push('/buy-your-bike');
              }}
              color="primary"
              variant="contained"
            >
              VENDENOS TU BIKE
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>

      {/* Store List Modal */}
      <StoreModal open={storeModalOpen} onClose={handleStoreModalClose} />
    </>
  );
}
