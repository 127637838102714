/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { composedComponent } from 'utils/functions';
import { appActions } from 'commons/reducer';
import { useHistory } from 'react-router-dom';
import { useForm } from 'utils/hooks/useForm';
import years from 'utils/years';
import { specialCharFormatForBikes, serialSpecialCharFormat } from 'utils/consts';
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  ListItemText,
  List,
  ListItem,
  Link,
  DialogActions,
  ListItemIcon,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import styles from './styles';
import saga from './saga';
import BuyYourBikeForm from './components/buyYourBikeForm';
import { buyYourBikeActions } from './reducer';
import BikeCard from './components/BikeCard';

const CommissionModal = ({ openModal, setOpenModal }) => {
  const [amount, setAmount] = useState('');
  const [commission, setCommission] = useState(null);

  // Function to calculate commission based on the given amount
  const calculateCommission = (value) => {
    let commissionPrice = 0;
    let remainderValue = value;
    if (remainderValue > 6000000) {
      commissionPrice += (remainderValue - 6000000) * 0.1;
      remainderValue = 6000000;
    }
    if (remainderValue > 4000000) {
      commissionPrice += (remainderValue - 4000000) * 0.12;
      remainderValue = 4000000;
    }
    if (remainderValue > 2000000) {
      commissionPrice += (remainderValue - 2000000) * 0.14;
      remainderValue = 2000000;
    }
    commissionPrice += remainderValue * 0.16;

    setCommission(commissionPrice);
  };

  const handleAmountChange = (e) => {
    const rawValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (rawValue === '') {
      setAmount('');
      setCommission(null);
      return;
    }

    const numericValue = parseInt(rawValue, 10); // Convert to a number
    const formattedValue = new Intl.NumberFormat('es-ES').format(numericValue); // Format with thousand separators

    setAmount(formattedValue);
    calculateCommission(numericValue); // Use the raw numeric value for calculations
  };

  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
          borderRadius: '10px',
          width: '400px',
          maxWidth: '100%', // Prevents overflowing
        }}>
        {/* Title */}
        <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '10px' }}>
          Comisión de Consignación
        </Typography>

        {/* Explanation */}
        <Typography
          variant="body2"
          style={{
            textAlign: 'justify',
            marginBottom: '15px',
            fontSize: '14px',
            fontWeight: 'bold',
            color: '#555',
          }}>
          Las comisiones son <strong>progresivas</strong> de acuerdo a cada tramo, es decir, el
          excedente que supere el tramo inmediatamente anterior tendrá una comisión inferior.
        </Typography>

        {/* Commission Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Valor venta bicicleta</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Comisión</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>$0 - $2,000,000</TableCell>
                <TableCell>16%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>$2,000,001 - $4,000,000</TableCell>
                <TableCell>14%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>$4,000,001 - $6,000,000</TableCell>
                <TableCell>12%</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>$6,000,001 o más</TableCell>
                <TableCell>10%</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Commission Calculator */}
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Typography variant="subtitle1">Calcula tu comisión</Typography>
          <TextField
            label="Valor de la bicicleta"
            variant="outlined"
            fullWidth
            value={amount}
            onChange={handleAmountChange}
            style={{ marginTop: '10px' }}
          />
          {commission !== null && (
            <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}>
              Comisión estimada: ${commission.toLocaleString()}
            </Typography>
          )}
        </div>

        {/* Additional Reminder */}
        <Typography
          variant="body2"
          style={{
            textAlign: 'center',
            marginTop: '20px',
            fontSize: '13px',
            color: '#777',
          }}>
          <strong>Recuerda:</strong> El precio final es decidido en conjunto con el equipo{' '}
          <strong>BIKFY</strong>.
        </Typography>
      </div>
    </Modal>
  );
};

const associatedStores = [
  { name: 'BIKFYLAB', url: '/publish-bike' }, // Replace with actual URL if available
  { name: 'Specialized', url: 'https://www.specialized.com/cl/es' },
  { name: 'Giant', url: 'https://giant-bicycles.cl/' },
  { name: 'Colnago', url: 'https://www.dolomitas.cl/' },
  { name: 'Wilier', url: 'https://all4bikers.cl/' },
  { name: 'BMC', url: 'https://www.365imports.cl/' },
  { name: 'Aurum', url: 'https://www.365imports.cl/' },
  { name: 'De Rosa', url: 'https://www.365imports.cl/' },
  { name: 'BH', url: 'https://www.epicbike.cl/' },
  { name: 'Mondraker', url: 'https://extremezone.cl/' },
];

const StoreModal = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
    <DialogTitle>Tiendas Asociadas</DialogTitle>
    <DialogContent dividers>
      <List>
        {associatedStores.map((store, index) => (
          <ListItem key={index} divider>
            <ListItemIcon>
              <LinkIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Link href={store.url} target="_blank" rel="noopener noreferrer" underline="hover">
                  {store.name}
                </Link>
              }
            />
          </ListItem>
        ))}
      </List>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Cerrar
      </Button>
    </DialogActions>
  </Dialog>
);

const BuyYourBike = (props) => {
  const { classes, user, purchasableBikes, formControls } = props;
  const { fetchPurchasableBikes, sendSimulation } = props.actions;
  const history = useHistory();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [storeModalOpen, setStoreModalOpen] = useState(false);

  // Example list of stores (Replace with dynamic data if needed)

  const handleStoreModalOpen = () => {
    setStoreModalOpen(true);
  };

  const handleStoreModalClose = () => {
    setStoreModalOpen(false);
  };

  useEffect(() => {
    if (user) {
      fetchPurchasableBikes();
    }
  }, []);

  useEffect(() => {
    props.actions.fetchBrands();
    props.actions.fetchModels();
    props.actions.fetchCategories();
    props.actions.fetchSizes();
    props.actions.fetchRims();
  }, [
    props.actions.fetchBrands,
    props.actions.fetchModels,
    props.actions.fetchCategories,
    props.actions.fetchSizes,
    props.actions.fetchRims,
    props.actions.fetchCurrencies,
  ]);

  const changeControls = useCallback((name, value) => {
    props.actions.changeControls({
      ...(name === 'brandId' ? { modelId: '' } : {}),
      ...(name === 'vehicleTypeId' ? { brandId: '', modelId: '', categoryId: '' } : {}),
      ...(name === 'vehicleTypeId' && value === ''
        ? {
            brandId: '',
            modelId: '',
            categoryId: '',
          }
        : {}),
      [name]: value,
    });
  }, []);

  const {
    onChange,
    onSubmit,
    errors,
    validateFields,
    validateAll,
    validateFieldsWithoutSettingErrors,
  } = useForm(props.formControls, null, {
    onChange: changeControls,
    validations: {
      version: [
        {
          check: (value) => !specialCharFormatForBikes.test(value),
          message: 'Carácteres especiales no están permitidos',
        },
      ],
      categoryId: [
        {
          check: (value) => !!value,
          message: 'La Categoría es requerida',
        },
      ],
      brandId: [
        {
          check: (value) => !!value,
          message: 'La Marca es requerida',
        },
      ],
      modelId: [
        {
          check: (value) => !!value,
          message: 'El Modelo es requerido',
        },
      ],
      sizeId: [
        {
          check: (value) => !!value,
          message: 'La Talla es requerida',
        },
      ],
      year: [
        {
          check: (value) => !!value,
          message: 'El Año es requerido',
        },
      ],
      rimId: [
        {
          check: (value) => !!value,
          message: 'El Aro es requerido',
        },
      ],
      email: [
        {
          check: (value) => !!value,
          message: 'El Correo es requerido',
        },
      ],
      name: [
        {
          check: (value) => !!value,
          message: 'El Nombre es requerido',
        },
      ],
      lastname: [
        {
          check: (value) => !!value,
          message: 'El Apellido es requerido',
        },
      ],
      phone: [
        {
          check: (value) => !!value && value.trim().length > 0,
          message: 'El Teléfono celular es requerido',
        },
        {
          check: (value) =>
            value.substr(0, 3) === '+56' ? value.trim().length >= 12 : value.trim().length >= 8,
          message: 'El formato es incorrecto',
        },
      ],
      amount: [
        {
          check: (value) => !!value,
          message: 'El valor es requerido',
        },
      ],
      condition: [
        {
          check: (value) => !!value,
          message: 'El valor es requerido',
        },
      ],
    },
  });

  const sizes = Object.values(props.sizes).map((s) => ({
    value: s?.id,
    label: s?.name,
  }));

  const rims = props.order?.rims
    ?.map((b) => props.rims[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const brands = props.order?.brands
    ?.map((b) => props.brands[b])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const models = props.order?.models
    ?.map((m) => props.models[m])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
      brandId: a?.brandId,
    }));

  const categories = props.order?.categories
    ?.map((c) => props.categories[c])
    .map((a) => ({
      value: a?.id,
      label: a?.name,
    }));

  const filteredModels = useMemo(
    () => models.filter((m) => m.brandId === props.formControls.brandId),
    [props.formControls.brandId, models],
  );

  const inputs = [
    {
      name: 'name',
      label: 'Nombre',
      type: 'text',
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'lastname',
      label: 'Apellido',
      type: 'text',
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'email',
      label: 'Email',
      type: 'text',
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'phone',
      label: 'Telefono',
      type: 'text',
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'categoryId',
      label: 'Categoría',
      type: 'select',
      options: categories,
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'brandId',
      label: 'Marca',
      type: 'select',
      options: brands,
      info: true,
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'modelId',
      label: 'Modelo',
      type: 'select',
      options: filteredModels,
      info: true,
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'version',
      label: 'Versión',
      type: 'text',
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'sizeId',
      label: 'Talla',
      visible: Boolean(props?.formControls?.vehicleTypeId),
      type: 'select',
      options: sizes,
    },
    {
      name: 'year',
      label: 'Año',
      type: 'select',
      options: years(),
      visible: Boolean(props?.formControls?.vehicleTypeId),
    },
    {
      name: 'rimId',
      label: 'Aro',
      visible: Boolean(props?.formControls?.vehicleTypeId),
      type: 'select',
      options: rims,
    },
    {
      name: 'amount',
      label: 'Valor esperado',
      type: 'number',
      inputProp: true,
      md: 3,
      visible: true,
    },
    {
      name: 'condition',
      label: 'Estado de la bicicleta',
      type: 'select',
      visible: true,
      options: [
        { value: 'Like New', label: 'Como nuevo' },
        { value: 'Good Condition', label: 'Buen estado' },
        { value: 'Acceptable', label: 'Aceptable' },
      ],
    },
  ];

  // Plan descriptions and titles
  const plans = [
    {
      id: 1,
      title: 'CONSIGNACIÓN',
      value: 'Consignment',
      description: `
        
        Vende tu bicicleta de manera rápida y sin preocupaciones: ¡Nosotros nos encargamos de todo! Tú solo debes cubrir los gastos de los servicios y sustitución de componentes en caso que tu bicicleta lo requiera. 
        
        Calcula tu % de comisión según el precio de venta.
      `,
      moreInfo: true,
    },
    {
      id: 2,
      title: 'RENOVACIÓN POR NUEVA O SEMINUEVA',
      value: 'Renew',
      description: (
        <>
          🎉 ¡Déjanos tu bici actual y tú solo preocúpate de elegir a tu nueva compañera! 🎉 Recibe
          un cupón digital al instante para usarlo en la compra de una bicicleta nueva en{' '}
          <Tooltip title="Click para ver todas las tiendas" placement="top">
            <span
              onClick={handleStoreModalOpen}
              style={{ color: '#1976d2', cursor: 'pointer', fontWeight: 'bold' }}>
              nuestras tiendas asociadas{' '}
            </span>
          </Tooltip>
          o una semi-nueva nuestra.
          <br />
          <br />
          Condiciones: ✔️ El precio de tu bici se ajusta con un 18% a 23% promedio de descuento
          sobre el valor de mercado.
        </>
      ),
    },
    {
      id: 3,
      title: 'SOLO VENTA',
      value: 'Sale',

      description: `
         😟Por lo general, es donde más se castiga el valor de tu bicicleta.
        
        Condiciones:
        ✔️ El precio de tu bicicleta se ajusta con un 25% a 30% promedio de descuento sobre el valor de mercado. Recibirás el dinero en un máximo de 48 horas.
      `,
    },
  ];

  // Handle when a plan is clicked
  const handlePlanClick = (planId) => {
    setSelectedPlan(planId);
  };

  return (
    <>
      {/* <p> {JSON.stringify(models, 2)}</p> */}
      {!selectedPlan ? (
        <Grid
          container
          spacing={3}
          style={{
            margin: '2rem 0',
            justifyContent: 'center', // Centers the cards horizontally
            maxWidth: '100%', // Prevents overflowing
          }}>
          {plans.map((plan) => (
            <Grid
              item
              key={plan.id}
              style={{
                maxWidth: '350px', // Limit the width of each card
                flex: '1 1 auto', // Ensures responsiveness
              }}>
              <Card
                style={{
                  padding: '1rem',
                  textAlign: 'center',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                  position: 'relative', // Allows positioning of the info icon
                }}>
                {/* Info Icon - Only shows if moreInfo is true */}
                {/* {plan.moreInfo && (
                  <Tooltip title="Más información">
                    <IconButton
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                      }}
                      onClick={() => setOpenModal(true)}>
                      <InfoIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                )} */}

                <CardContent style={{ flexGrow: 1 }}>
                  <Typography
                    variant="h6"
                    component="h1"
                    style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                    {plan.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      whiteSpace: 'pre-line',
                      marginBottom: '1.5rem',
                      textAlign: 'left',
                    }}>
                    {plan.description}
                  </Typography>

                  {/* "Ver más" clickable text if moreInfo is true */}
                  {plan.moreInfo && (
                    <Typography
                      variant="body2"
                      component="h2"
                      style={{ color: '#1976d2', cursor: 'pointer', fontWeight: 'bold' }}
                      onClick={() => setOpenModal(true)}>
                      Ver más
                    </Typography>
                  )}
                </CardContent>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handlePlanClick(plan.id)}>
                  Continuar
                </Button>
              </Card>
            </Grid>
          ))}

          <CommissionModal openModal={openModal} setOpenModal={setOpenModal} />
          <StoreModal open={storeModalOpen} onClose={handleStoreModalClose} />
        </Grid>
      ) : (
        <>
          <BuyYourBikeForm
            className={classes}
            profileComplete={user?.user?.dataComplete}
            onSubmit={(data) => sendSimulation(data)}
            onControlChange={onChange}
            errors={errors}
            inputs={inputs}
            controls={props.formControls}
            validateFields={validateFields}
            actions={props.actions}
            offerType={plans[selectedPlan - 1].value || 'Consignment'}
            offerLabel={plans[selectedPlan - 1].title || ''}
            history={history}
          />

          <Grid container direction="column" alignItems="center" justifyContent="center">
            {!purchasableBikes.length && user ? (
              <Grid style={{ margin: '2rem' }}>
                <Typography variant="h4">
                  No tienes bicicletas registradas o estas no cumplen con las condiciones para poder
                  ser compradas.
                </Typography>
              </Grid>
            ) : (
              purchasableBikes.map((transfer) => (
                <Grid item key={transfer.id} style={{ width: '80%' }}>
                  <BikeCard
                    transfer={transfer}
                    bikeData={{
                      categoryName: transfer?.bike?.category?.name,
                      brandName: transfer?.bike?.brand?.name,
                      modelName: transfer?.bike?.model?.name,
                      bikeYear: transfer?.bike?.year,
                      sizeName: transfer?.bike?.size?.name,
                      rimName: transfer?.bike?.rim?.name,
                    }}
                    offerType={plans[selectedPlan - 1].value || 'Consignment'}
                    sendSimulationEmail={sendSimulation}
                    history={history}
                    user={user?.user}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default composedComponent(BuyYourBike, saga, {
  saga: 'sagaBuyYourBike',
  middlewares: [withStyles(styles)],
  states: [
    'buyYourBike.purchasableBikes',
    'app.objects',
    'app.order',
    'app.user',
    'app.objects.brands',
    'app.objects.models',
    'app.objects.categories',
    'app.objects.sizes',
    'app.objects.rims',
    'buyYourBike.formControls',
  ],
  actions: [buyYourBikeActions, appActions],
});
